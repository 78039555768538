@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Nunito:wght@400;500;600;700&family=Raleway:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", "Raleway", "Nunito", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.PhoneInputInput {
  border: none;
  background-color: transparent;
}

/* Phone Input package comes with a few styles we do not need so we disable them here */
.special-label {
  display: none;
}

.react-tel-input .form-control {
  width: 22.5rem !important;
  background-color: #e5e7eb !important;
  padding: 30px;
}

@media screen and (max-width: 768px) {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}

/* .react-tel-input .form-control:focus {
	width: 14rem !important;
	border: 1px solid purple !important;
	background-color: #e5e7eb !important;
} */

.react-tel-input .flag-dropdown {
  border: 1px solid #e5e7eb !important;
  background-color: #e5e7eb !important;
  padding: 6px 0 !important;
}

/* .react-tel-input .flag-dropdown:focus {
	border: 1px solid purple !important;
	background-color: #e5e7eb !important;
} */

/* Custom Scrollbars */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 6px;
  background: #56265d;
  border-radius: 10px;
}

.landing-bg {
  height: 100%;
  background-image: url("../public/assets/images/landing-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* React router renders links as plain anchor tags in the DOM */
/* I make one particualar anchor tag block so I apply some styles for consistency */

a[href="/complaint/single"] {
  display: block;
  width: 100%;
}

/* remove arrows from number input */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
